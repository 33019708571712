<template>
    <ol
        v-if="segments"
        class="breadcrumb"
    >
        <li class="breadcrumb-item">
            <a :href="rootPath">{{ appName }}</a>
        </li>
        <li
            v-for="(segment, i) in segments"
            class="breadcrumb-item text-capitalize"
        >
            {{ segment }}
        </li>
    </ol>
</template>

<script>
    export default {
        props: {
            path: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                appName: process.env.MIX_APP_NAME.replace(' API',''),
                rootPath: '/',
                pathSeparator: '/',
                segments: false
            }
        },

        mounted() {
            this.pathToSegment();
        },

        methods: {
            pathToSegment() {
                this.segments = this.path.split(this.pathSeparator).filter(itm => itm);
            }
        }
    }
</script>
